<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.pendingTasks') }}</template>
    <template v-slot:header-actions>
      <v-row no-gutters justify="end">
        <v-col cols="7">
          <SingleSearchFilter
            transition="slide-y-transition"
            class-name="px-3 pt-0"
            :context="context"
            :fields="searchFields"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:content>
      <v-data-table
        :headers="headers"
        :items="pendingTasks"
        class="elevation-0"
        :loading="loading"
        :page.sync="page"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        must-sort
        :items-per-page.sync="itemsPerPage"
        :footer-props="{ itemsPerPageOptions: itemsPerPageArray }"
        :server-items-length="pendingTasksCount"
      >
        <template v-slot:[`item.due_on`]="{ item }">
          {{ transformDate(item.due_on) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top transition="fade-transition">
            <template v-slot:activator="{ on }">
              <v-btn icon small :to="goToDeal(item.entity)" v-on="on">
                <v-icon small>{{ $vuetify.icons.values.view }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('labels.view') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </template>
  </VWidget>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { formatDT } from '@argon/app/helpers'
import { VWidget, SingleSearchFilter } from '@argon/app/components'
import { Datatable } from '@argon/app/mixins'
import { NAMESPACE, PENDINGTASKS } from '../store'

export default {
  name: 'PendingTasks',
  mixins: [Datatable],
  components: {
    VWidget,
    SingleSearchFilter
  },
  data() {
    return {
      headers: [
        { text: this.$t('labels.dueDate'), value: 'due_on', align: 'left' },
        { text: this.$t('labels.task'), value: 'text', align: 'left' },
        { text: this.$t('labels.owner'), value: 'owner.display', align: 'left' },
        { text: this.$t('labels.clients'), value: 'entity.client_names', align: 'left' },
        { text: '', value: 'actions', sortable: false, width: 20 }
      ],
      searchFields: [
        { label: 'owner_name', type: 'text' },
        { label: 'text', type: 'text' }
      ],

      loading: true,
      sortBy: 'due_on',
      sortDesc: false,
      context: PENDINGTASKS
    }
  },
  computed: {
    ...mapGetters(ME_NAMESPACE, ['timezone']),
    ...mapState(NAMESPACE, ['pendingTasks', 'pendingTasksCount'])
  },
  methods: {
    ...mapActions(NAMESPACE, ['getPendingTasks', 'clearPendingTasks']),
    transformDate(value) {
      return value ? formatDT(value, { format: 'DD/MM/YYYY', tz: this.timezone }) : ''
    },
    goToDeal(deal) {
      return { path: `/deals/${deal.deal_type}/cards/${deal.id}` }
    },
    fetchData() {
      this.loading = true
      this.getPendingTasks({ pager: this.pager }).finally(() => {
        this.loading = false
      })
    }
  },
  created() {
    this.fetchData()
  },
  destroyed() {
    this.clearPendingTasks()
  }
}
</script>

<style></style>
