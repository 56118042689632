<template>
  <v-card outlined>
    <v-list dense flat class="pa-0">
      <v-list-item>
        <v-list-item-avatar>
          <v-icon :color="style.color">{{ $vuetify.icons.values[style.icon] }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.count }} </v-list-item-title>
          <v-list-item-subtitle> {{ $t(`dashboards.${cleanedLabel}`) }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { getSummaryStyle } from '../../utils'
export default {
  name: 'SummaryItem',
  props: {
    category: { type: String, required: true },
    item: { type: Object, required: true }
  },
  computed: {
    cleanedLabel() {
      return this.item.label.replaceAll('.', '|')
    },
    style() {
      return getSummaryStyle(this.item.label, this.category)
    }
  }
}
</script>

<style></style>
