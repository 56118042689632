<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.PendingContactDeals') }}</template>
    <template v-slot:header-actions>
      <v-row no-gutters justify="end">
        <v-col cols="7">
          <SingleSearchFilter
            transition="slide-y-transition"
            class-name="px-3 pt-0"
            :context="context"
            :fields="searchFields"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:content>
      <v-data-table
        :headers="headers"
        :items="pendingContactDeals"
        class="elevation-0"
        :loading="loading"
        :page.sync="page"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        must-sort
        :items-per-page.sync="itemsPerPage"
        :footer-props="{ itemsPerPageOptions: itemsPerPageArray }"
        :server-items-length="pendingContactDealsCount"
      >
        <template v-slot:[`item.overdue`]="{ item }">
          <v-icon small v-if="item.contact_overdue" color="error">{{ $vuetify.icons.values.stateFailure }}</v-icon>
        </template>
        <template v-slot:[`item.last_step_on`]="{ item }">
          {{ transformDate(item.last_step_on) }}
        </template>
        <template v-slot:[`item.clients`]="{ item }">
          {{ item.client_names ? item.client_names.join(', ') : '' }}
        </template>
        <template v-slot:[`item.advisor`]="{ item }">
          {{ item.advisor ? item.advisor.display : $t('labels.undefined') }}
        </template>
        <template v-slot:[`item.broker`]="{ item }">
          {{ item.broker.display }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top transition="fade-transition">
            <template v-slot:activator="{ on }">
              <v-btn icon small :to="goToDeal(item)" v-on="on">
                <v-icon small>{{ $vuetify.icons.values.view }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('labels.view') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </template>
  </VWidget>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { formatDT } from '@argon/app/helpers'
import { VWidget, SingleSearchFilter } from '@argon/app/components'
import { Datatable } from '@argon/app/mixins'
import { NAMESPACE, PENDINGCONTACTDEALS } from '../store'
import dealPerimeter from '../../deals/perimeters'

export default {
  name: 'PendingContactDeals',
  mixins: [Datatable],
  perimeters: [dealPerimeter],
  components: {
    VWidget,
    SingleSearchFilter
  },
  data() {
    return {
      headers: [
        { text: '', value: 'overdue', sortable: false, width: 20 },
        { text: this.$t('labels.stepOn'), value: 'last_step_on' },
        { text: this.$t('labels.refnum'), value: 'refnum', sortable: false },
        { text: this.$t('labels.clients'), value: 'clients', sortable: false },
        { text: this.$t('labels.broker'), value: 'broker', sortable: false },
        { text: this.$t('labels.advisor'), value: 'advisor', sortable: false },
        { text: '', value: 'actions', sortable: false, width: 20 }
      ],
      searchFields: [
        { label: 'client_names', type: 'text' },
        { label: 'advisor_name', type: 'text' },
        { label: 'broker_name', type: 'text' },
        { label: 'refnum', type: 'text' }
      ],
      loading: true,
      context: PENDINGCONTACTDEALS
    }
  },
  computed: {
    ...mapState(NAMESPACE, ['pendingContactDeals', 'pendingContactDealsCount']),
    ...mapState(ME_NAMESPACE, ['profile']),
    ...mapGetters(ME_NAMESPACE, ['timezone']),
    identifier() {
      return this.$deal.isAllowed('manage') ? 'manager' : this.profile ? this.profile.refid : null
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['getPendingContactDeals', 'clearPendingContactDeals']),
    transformDate(value) {
      return value ? formatDT(value, { format: 'DD/MM/YYYY', tz: this.timezone }) : ''
    },
    goToDeal(deal) {
      return {
        path: `/deals/${deal.deal_type}/cards/${deal.id}`
      }
    },
    fetchData() {
      return new Promise((resolve, reject) => {
        this.loading = true
        this.getPendingContactDeals({ pager: this.pager })
          .then(resolve)
          .catch(reject)
          .finally(() => {
            this.loading = false
          })
      })
    }
  },
  created() {
    this.fetchData()
  },
  destroyed() {
    this.clearPendingContactDeals()
  }
}
</script>

<style></style>
