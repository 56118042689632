<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('csvExports') }}</template>
    <template v-slot:header-actions>
      <v-tooltip top transition="fade-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon small @click="generate" v-on="on">
            <v-icon small>{{ $vuetify.icons.values.reset }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('labels.generateExports') }}</span>
      </v-tooltip>
    </template>
    <template v-slot:content>
      <v-data-table :headers="headers" :items="legacyExports" class="elevation-0" :loading="loading">
        <template v-slot:[`item.icon`]="{ item }">
          <v-icon small v-text="$vuetify.icons.values[item.file.extension] || $vuetify.icons.values.file" />
        </template>
        <template v-slot:[`item.created_on`]="{ item }">
          {{ transformDate(item.created_on) }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <DownloadBtn :file="item.file" />
        </template>
      </v-data-table>
    </template>
  </VWidget>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { NAMESPACE as REALTIME_NAMESPACE } from '@argon/realtime/store'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { formatDT } from '@argon/app/helpers'
import { VWidget, DownloadBtn } from '@argon/app/components'
import { NAMESPACE } from '../store'

export default {
  name: 'CSVExports',
  components: {
    VWidget,
    DownloadBtn
  },
  data() {
    return {
      loading: true,
      headers: [
        { text: '', value: 'icon' },
        { text: '', value: 'file.display' },
        { text: '', value: 'file.filename' },
        { text: '', value: 'created_on' },
        { text: '', value: 'actions' }
      ]
    }
  },
  computed: {
    ...mapState(NAMESPACE, ['legacyExports']),
    ...mapGetters(ME_NAMESPACE, ['timezone'])
  },
  methods: {
    ...mapActions(REALTIME_NAMESPACE, ['addListener', 'removeListener']),
    ...mapActions(NAMESPACE, ['getLegacyExports', 'generateLegacyExports']),
    transformDate(value) {
      return value ? formatDT(value, { format: 'DD/MM/YYYY', tz: this.timezone }) : ''
    },
    generate() {
      this.loading = true
      this.generateLegacyExports()
        .then(() => {
          return this.getLegacyExports()
        })
        .then(() => {
          this.loading = false
        })
    }
  },
  created() {
    this.getLegacyExports()
      .then(() => {
        this.addListener({
          category: 'export',
          identifier: 'legacy'
        })
      })
      .finally(() => {
        this.loading = false
      })
  },
  destroyed() {
    this.removeListener({
      category: 'export',
      identifier: 'legacy'
    })
  }
}
</script>

<i18n>
{
    "en": {
        "csvExports": "CSV Exports"
    },
    "fr": {
        "csvExports": "Exports CSV"
    }
}
</i18n>
