<template>
  <div color="transparent">
    <v-tabs v-model="tab">
      <v-tab>{{ $t('labels.today') }}</v-tab>
      <v-tab>{{ $t('labels.thisWeek') }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item class="mt-4 pa-0">
        <summary-category :title="$t('labels.today')" />
      </v-tab-item>
      <v-tab-item class="mt-4 pa-0">
        <summary-category :title="$t('labels.thisWeek')" category="weekly" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { NAMESPACE } from '../store'
import SummaryCategory from './SummaryCategory'

export default {
  name: 'OrgSummary',
  components: { SummaryCategory },
  data: () => ({
    tab: null
  })
}
</script>

<style></style>

<i18n>
{
  "en": {
    "dealsThisWeek": "Deals this week",
    "dealsToday": "dealsToday"
  },
  "fr": {
    "dealsThisWeek": "Dossiers cette semaine",
    "dealsToday": "Dossiers aujourd'hui"
  }
}
</i18n>
