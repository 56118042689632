<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.completedBrokerDeals') }}</template>
    <template v-slot:header-actions>
      <v-row no-gutters justify="end">
        <v-col cols="7">
          <SingleSearchFilter
            transition="slide-y-transition"
            class-name="px-3 pt-0"
            :context="context"
            :fields="searchFields"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:content>
      <v-data-table
        :headers="headers"
        :items="completedBrokerDeals"
        class="elevation-0"
        :loading="loading"
        :page.sync="page"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        must-sort
        :items-per-page.sync="itemsPerPage"
        :footer-props="{ itemsPerPageOptions: itemsPerPageArray }"
        :server-items-length="completedBrokerDealsCount"
      >
        <template v-slot:[`item.clients`]="{ item }">
          {{ item.client_names ? item.client_names.join(', ') : '' }}
        </template>
        <template v-slot:[`item.advisor`]="{ item }">
          {{ item.advisor ? item.advisor.display : $t('labels.undefined') }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top transition="fade-transition">
            <template v-slot:activator="{ on }">
              <v-btn icon small :to="goToDeal(item)" v-on="on">
                <v-icon small>{{ $vuetify.icons.values.view }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('labels.view') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </template>
  </VWidget>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { formatDT } from '@argon/app/helpers'
import { Datatable } from '@argon/app/mixins'
import { VWidget, SingleSearchFilter } from '@argon/app/components'
import { NAMESPACE, COMPLETEDBROKERDEALS } from '../store'
import dealPerimeter from '../../deals/perimeters'

export default {
  name: 'CompletedBrokerDeals',
  mixins: [Datatable],
  perimeters: [dealPerimeter],
  components: {
    VWidget,
    SingleSearchFilter
  },
  data() {
    return {
      headers: [
        { text: this.$t('labels.display'), value: 'display' },
        { text: this.$t('labels.refnum'), value: 'refnum' },
        { text: this.$t('labels.clients'), value: 'clients', sortable: false },
        { text: this.$t('labels.advisor'), value: 'advisor', sortable: false },
        { text: '', value: 'actions', sortable: false, width: 20 }
      ],
      searchFields: [
        { label: 'client_names', type: 'text' },
        { label: 'advisor_name', type: 'text' },
        { label: 'broker_name', type: 'text' },
        { label: 'refnum', type: 'text' }
      ],
      loading: true,
      context: COMPLETEDBROKERDEALS
    }
  },
  computed: {
    ...mapState(NAMESPACE, ['completedBrokerDeals', 'completedBrokerDealsCount']),
    ...mapState(ME_NAMESPACE, ['profile']),
    ...mapGetters(ME_NAMESPACE, ['timezone'])
  },
  methods: {
    ...mapActions(NAMESPACE, ['getCompletedBrokerDeals', 'clearCompletedBrokerDeals']),
    transformDate(value) {
      return value ? formatDT(value, { format: 'DD/MM/YYYY', tz: this.timezone }) : ''
    },
    goToDeal(deal) {
      return { path: `/deals/${deal.deal_type}/cards/${deal.id}` }
    },
    fetchData() {
      this.loading = true
      this.getCompletedBrokerDeals({ pager: this.pager }).finally(() => {
        this.loading = false
      })
    }
  },
  created() {
    this.fetchData()
  },
  destroyed() {
    this.clearCompletedBrokerDeals()
  }
}
</script>
