<template>
  <v-container fluid>
    <AppTitleBar>
      <template v-slot:title>{{ $t('labels.overview') }}</template>
      <template slot="actions"></template>
    </AppTitleBar>

    <v-loading v-if="loading" />
    <div v-else>
      <v-tabs v-model="activeTab" slider-color="grey darken-1">
        <v-tab ripple v-if="$deal.isAllowed('atLeastManager')">{{ $t('summary') }}</v-tab>
        <v-tab ripple>{{ $t('lists') }}</v-tab>
        <v-tab ripple v-if="$deal.isAllowed('atLeastManager')">{{ $t('exports') }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item class="mt-4" v-if="$deal.isAllowed('atLeastManager')">
          <org-summary />
        </v-tab-item>
        <v-tab-item class="mt-4">
          <v-row>
            <v-col cols="12" v-if="$deal.isAllowed('atLeastAdvisor')">
              <PendingContactDeals />
            </v-col>
            <v-col cols="12" v-if="$deal.isAllowed('isAdvisor')">
              <PendingTasks />
            </v-col>
            <v-col cols="12" v-if="$deal.isAllowed('atLeastManager')">
              <PendingTasksManager />
            </v-col>
            <v-col cols="12" v-if="$deal.isAllowed('isAdvisor')">
              <OverdueTasks />
            </v-col>
            <v-col cols="12" v-if="$deal.isAllowed('atLeastManager')">
              <OverdueTasksManager />
            </v-col>
            <v-col cols="12" v-if="$deal.isAllowed('atLeastAdvisor')">
              <NonCompliantDeals />
            </v-col>
            <v-col cols="12" v-if="$deal.isAllowed('atLeastManager')">
              <OverdueBrokers />
            </v-col>
            <v-col cols="12" v-if="$deal.isAllowed('isBroker')">
              <PendingBrokerDeals />
            </v-col>
            <v-col cols="12" v-if="$deal.isAllowed('isBroker')">
              <ContactedBrokerDeals />
            </v-col>
            <v-col cols="12" v-if="$deal.isAllowed('isBroker')">
              <SoldBrokerDeals />
            </v-col>
            <v-col cols="12" v-if="$deal.isAllowed('isBroker')">
              <CompletedBrokerDeals />
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item class="mt-4 px-2" v-if="$deal.isAllowed('atLeastManager')">
          <CSVExports />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-container>
</template>

<script>
import { AppTitleBar, VLoading } from '@argon/app/components'
import {
  OrgSummary,
  OverdueTasks,
  OverdueTasksManager,
  PendingTasks,
  PendingTasksManager,
  OverdueBrokers,
  NonCompliantDeals,
  PendingContactDeals,
  PendingBrokerDeals,
  ContactedBrokerDeals,
  SoldBrokerDeals,
  CompletedBrokerDeals,
  CSVExports
} from '../components'
import organizationPerimeter from '@argon/iam/organizations/perimeters'
import flowPerimeter from '@argon/evo/flows/perimeters'
import boardPerimeter from '@argon/evo/boards/perimeters'
import dealPerimeter from '../../deals/perimeters'

export default {
  components: {
    AppTitleBar,
    OrgSummary,
    OverdueTasks,
    OverdueTasksManager,
    PendingTasks,
    PendingTasksManager,
    OverdueBrokers,
    NonCompliantDeals,
    PendingContactDeals,
    PendingBrokerDeals,
    ContactedBrokerDeals,
    SoldBrokerDeals,
    CompletedBrokerDeals,
    CSVExports,
    VLoading
  },
  perimeters: [organizationPerimeter, flowPerimeter, boardPerimeter, dealPerimeter],
  data: () => ({
    activeTab: null,
    loading: false
  }),
  computed: {}
}
</script>

<i18n>
{
  "en": {
    "summary": "Summary",
    "exports": "Exports",
    "lists": "Monitor"
  },
  "fr": {
    "summary": "Sommaire",
    "exports": "Exports",
    "lists": "À surveiller"
  }
}
</i18n>
