<template>
  <div>
    <v-row>
      <v-col cols="12" v-for="(section, idx) in items" :key="`daily-${idx}`">
        <summary-section :section-label="section.label" :items="section.items" :category="category" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { NAMESPACE as REALTIME_NAMESPACE } from '@argon/realtime/store'
import { mapActions, mapState } from 'vuex'
import { NAMESPACE } from '../store'
import SummarySection from './SummarySection'
export default {
  name: 'SummaryCategory',
  components: { SummarySection },
  props: {
    title: { type: String, required: true },
    category: { type: String, default: 'daily' }
  },
  computed: {
    ...mapState(NAMESPACE, ['dailies', 'weeklies']),
    isWeekly() {
      return this.category === 'weekly'
    },
    realtimeCategory() {
      return `${this.category}Summary`
    },
    items() {
      return this.isWeekly ? this.weeklies : this.dailies
    },
    realtimeKey() {
      return this.$store.getters[`${REALTIME_NAMESPACE}/realtimeKey`]({
        category: this.realtimeCategory,
        identifier: 'all'
      })
    }
  },
  methods: {
    ...mapActions(REALTIME_NAMESPACE, ['addListener', 'removeListener']),
    ...mapActions(NAMESPACE, ['getWeeklySummary', 'getDailySummary']),
    fetchData() {
      return this.isWeekly ? this.getWeeklySummary() : this.getDailySummary()
    }
  },
  watch: {
    realtimeKey: function (v, oldV) {
      if (oldV) {
        this.fetchData()
      }
    }
  },
  created() {
    this.fetchData().then(() => {
      this.addListener({
        category: this.realtimeCategory,
        identifier: 'all'
      })
    })
  },
  destroyed() {
    this.removeListener({
      category: this.realtimeCategory,
      identifier: 'all'
    })
  }
}
</script>
