<template>
  <div>
    <span class="subheading">{{ $t(`dashboards.${cleanedLabel}`) }}</span>
    <v-row dense>
      <v-col cols="3" v-for="(item, idx) in sorted" :key="`${sectionLabel}-${idx}`">
        <summary-item :item="item" :category="category" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { orderBy } from 'lodash/collection'
import SummaryItem from './SummaryItem'

export default {
  name: 'SummarySection',
  components: { SummaryItem },
  props: {
    sectionLabel: { type: String, required: true },
    category: { type: String, default: 'daily' },
    items: { type: Array }
  },
  computed: {
    cleanedLabel() {
      return this.sectionLabel.replaceAll('.', '|')
    },
    sorted() {
      return orderBy(this.items, ['label'], ['asc'])
    }
  }
}
</script>

<style></style>
