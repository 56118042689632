<template>
  <BaseNavItem :title="$t('title')" :icon="$vuetify.icons.values.home" :route="{ name: 'overview' }" />
</template>

<script>
import { BaseNavItem } from '@argon/app/components'

export default {
  components: { BaseNavItem }
}
</script>

<i18n>
{
  "en": {
    "title": "@:labels.overview"
  },
  "fr": {
    "title": "@:labels.overview"
  }
}
</i18n>
