<template>
  <VWidget content-class="pa-0">
    <template v-slot:title>{{ $t('labels.overdueBrokers') }}</template>
    <template v-slot:header-actions>
      <v-row no-gutters justify="end">
        <v-col cols="7">
          <SingleSearchFilter
            transition="slide-y-transition"
            class-name="px-3 pt-0"
            :context="context"
            :fields="searchFields"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:content>
      <v-data-table
        :headers="headers"
        :items="overdueBrokers"
        class="elevation-0"
        :loading="loading"
        :page.sync="page"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        must-sort
        :items-per-page.sync="itemsPerPage"
        :footer-props="{ itemsPerPageOptions: itemsPerPageArray }"
        :server-items-length="overdueBrokersCount"
      >
        <template v-slot:[`item.last_referral_on`]="{ item }">
          {{ transformDate(item.last_referral_on) }}
        </template>
      </v-data-table>
    </template>
  </VWidget>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import { formatDT } from '@argon/app/helpers'
import { VWidget, SingleSearchFilter } from '@argon/app/components'
import { Datatable } from '@argon/app/mixins'
import { NAMESPACE, OVERDUEBROKERS } from '../store'

export default {
  name: 'OverdueBrokers',
  mixins: [Datatable],
  components: {
    VWidget,
    SingleSearchFilter
  },
  data() {
    return {
      headers: [
        { text: this.$t('labels.display'), value: 'display', align: 'left' },
        { text: this.$t('labels.lastReferralDate'), value: 'last_referral_on', align: 'left' }
      ],
      searchFields: [{ label: 'display', type: 'text' }],
      loading: true,
      context: OVERDUEBROKERS
    }
  },
  computed: {
    ...mapState(NAMESPACE, ['overdueBrokers', 'overdueBrokersCount']),
    ...mapGetters(ME_NAMESPACE, ['timezone'])
  },
  methods: {
    ...mapActions(NAMESPACE, ['getOverdueBrokers', 'clearOverdueBrokers']),
    transformDate(value) {
      return value ? formatDT(value, { format: 'DD/MM/YYYY', tz: this.timezone }) : ''
    },
    fetchData() {
      this.loading = true
      this.getOverdueBrokers({ pager: this.pager }).finally(() => {
        this.loading = false
      })
    }
  },
  created() {
    this.fetchData()
  },
  destroyed() {
    this.clearOverdueBrokers()
  }
}
</script>

<style></style>
