var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VWidget',{attrs:{"content-class":"pa-0"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('labels.soldBrokerDeals')))]},proxy:true},{key:"header-actions",fn:function(){return [_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"7"}},[_c('SingleSearchFilter',{attrs:{"transition":"slide-y-transition","class-name":"px-3 pt-0","context":_vm.context,"fields":_vm.searchFields}})],1)],1)]},proxy:true},{key:"content",fn:function(){return [_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.soldBrokerDeals,"loading":_vm.loading,"page":_vm.page,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"must-sort":"","items-per-page":_vm.itemsPerPage,"footer-props":{ itemsPerPageOptions: _vm.itemsPerPageArray },"server-items-length":_vm.soldBrokerDealsCount},on:{"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.clients",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.client_names ? item.client_names.join(', ') : '')+" ")]}},{key:"item.advisor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.advisor ? item.advisor.display : _vm.$t('labels.undefined'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","to":_vm.goToDeal(item)}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.view))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.view')))])])]}}],null,true)})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }