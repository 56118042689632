var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VWidget',{attrs:{"content-class":"pa-0"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$t('csvExports')))]},proxy:true},{key:"header-actions",fn:function(){return [_c('v-tooltip',{attrs:{"top":"","transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":_vm.generate}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.reset))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('labels.generateExports')))])])]},proxy:true},{key:"content",fn:function(){return [_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.legacyExports,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.$vuetify.icons.values[item.file.extension] || _vm.$vuetify.icons.values.file)}})]}},{key:"item.created_on",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.transformDate(item.created_on))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('DownloadBtn',{attrs:{"file":item.file}})]}}],null,true)})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }